import Cookies from 'js-cookie';

const TOKEN_NAME = 'irisAdmin';
export class TokenContainer {

  hasToken() {
    return null != this.getToken();
  }

  getToken() {
    return Cookies.get(TOKEN_NAME);
  }

  setToken(token) {
    Cookies.set(TOKEN_NAME, token);
  }

  clearToken() {
    Cookies.remove(TOKEN_NAME);
  }

}