import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useMutationCoordinate } from '../../clients/graphql/graphql.hooks';
import { CircularProgress } from '@mui/material';
import { GQL_MFAContactVerify, GQL_MFAContactSendCodeProtected } from '../../clients/graphql/login.query';
import { useNavigate } from 'react-router-dom';
import { tokenContainer } from '../../clients/ClientRegistry';

export function LoginPage() {

  let navigate = useNavigate();
  const [phone, setPhone] = useState('+');
  const [challengeId, setChallengeId] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const [loginMutation, loginState] = useMutationCoordinate(GQL_MFAContactSendCodeProtected, {
    variables: {
      phone
    }
  });
  const [confirmMutation, confirmState] = useMutationCoordinate(GQL_MFAContactVerify, {
    variables: {
      challengeId,
      code
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    loginMutation()
      .then(resp => setChallengeId(
        resp?.data?.MFAContactSendCodeProtected?.mfaContact?.challengeId
      ))
      .catch(e => console.log(e));
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    confirmMutation()
      .then(resp => {
        const token = resp?.data?.MFAContactVerify?.auth?.token;
        if (token) {
          tokenContainer.setToken(token);
          navigate('/');
        } else {
          setError('Token returned was null or empty');
        }
      })
      .catch(e => console.log(e));
  };


  let loginForm = <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
    <TextField
      margin="normal"
      required
      fullWidth
      type="tel"
      id="phone"
      label="Phone Number"
      name="phone"
      autoComplete="phone"
      onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
      value={phone}
      autoFocus
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      disabled={phone.length < 12}
    >
      Sign In
    </Button>
  </Box>;

  const challengeForm = <Box component="form" onSubmit={handleConfirm} noValidate sx={{ mt: 1 }}>
    <Button onClick={() => setChallengeId(null)}> Go Back</Button>
    <TextField
      margin="normal"
      required
      fullWidth
      type="tel"
      id="twofa"
      label="2FA Code"
      name="twofa"
      autoComplete="twofa"
      onChange={(e) => setCode(e.target.value.slice(0, 6).toString().replace(/\D/g, ''))}
      value={code}
      autoFocus
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      disabled={code.length !== 6}
    >
      Confirm
    </Button>
  </Box>;

  const loadingDisp = <CircularProgress size={60} sx={{ margin: '30px' }} />;

  let display;
  if (loginState.loading || confirmState.loading) {
    display = loadingDisp;
  } else if (challengeId) {
    display = challengeForm;
  } else {
    display = loginForm;
  }

  return <Container component="main" maxWidth="xs">
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      {error && <Typography color="red">{error}</Typography>}
      {loginState?.error && <Typography color="red">{loginState?.error?.message || 'Error logging in'}</Typography>}
      {confirmState?.error && <Typography color="red">{confirmState?.error.message || 'Error with 2FA'}</Typography>}
      {display}
    </Box>
  </Container>;
}

function formatPhoneNumber(input) {
  const digits = input.slice(1,16).toString().replace(/\D/g, '');

  return '+' + digits;
}