import React from "react";
import {
    Box,
    Button,
    Card,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import {useNavigate} from "react-router-dom";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import InfoIcon from '@mui/icons-material/Info';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import DateRangeIcon from '@mui/icons-material/DateRange';
import GppBadIcon from '@mui/icons-material/GppBad';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import moment from "moment";

interface props{
    eventKey: string,
    data: eventData
}

interface eventData{
    userKey: string,
    userFullName: string,
    status: string,
    type: string,
    brokerName: string,
    portfolioKey: string,
    amount: string,
    createdAt: string,
    lastPolledAt: string,
    reason: string,
    autoPilotSettingsKey: string,
}
export default function PortfolioEventCrudPanel ({eventKey, data} : props) {

    const goTo = useNavigate()

    return(
        <Card elevation={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
                <br />
                <Typography variant="h6" component='div' >
                    Event Details
                </Typography>
                <Box sx={{ flexGrow: 2 }} />
            </Box>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={`User: ${data.userFullName}`} />
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    color='primary'
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => goTo(`/users/${data.userKey}`)}
                >
                    #{data.userKey}
                </Button>
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary={`Portfolio: ${data.portfolioKey}`} />
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    color='primary'
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => goTo(`/portfolios/${data.portfolioKey}`)}
                >
                    #{data.portfolioKey}
                </Button>
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <LocalAirportIcon />
                </ListItemIcon>
                <ListItemText primary={`Autopilot Settings Key: ${data.autoPilotSettingsKey}`} />
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    color='primary'
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => goTo(`/pilot-details/${data.autoPilotSettingsKey}`)}
                >
                    #{data.autoPilotSettingsKey}
                </Button>
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem sx={{marginTop: -1.5, marginBottom: -1.5}}>
                <ListItemIcon>
                    <HourglassFullIcon />
                </ListItemIcon>
                Status:&nbsp;
                {data.status === 'COMPLETED' && <p style={{ color: 'green' }}>{data.status}</p>}
                {data.status === 'FAILED' && <p style={{color: 'red', fontWeight: "bolder"}}>{data.status}</p>}
                {data.status === 'IN_PROGRESS' && <p style={{color: '#FFCC00', fontWeight: "bolder"}}>{data.status}</p>}
                {data.status === 'INITIATED' && <p style={{color: '#FFCC00', fontWeight: "bolder"}}>{data.status}</p>}
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={`Type: ${data.type}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={`Broker: ${data.brokerName}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <PaidIcon />
                </ListItemIcon>
                <ListItemText primary={`Amount: ${parseInt(data.amount)?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <SystemSecurityUpdateGoodIcon />
                </ListItemIcon>
                <ListItemText primary={`Created At: ${moment(data.createdAt).format('MM/DD/YYYY HH:mm')}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <DateRangeIcon />
                </ListItemIcon>
                <ListItemText primary={`Last Polled At: ${moment(data.lastPolledAt).format('MM/DD/YYYY HH:mm')}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem sx={{marginBottom: 1}}>
                <ListItemIcon>
                    <GppBadIcon />
                </ListItemIcon>
                Reason:&nbsp;
                {data.reason !== null && <ListItemText primary={`${data.reason}`} color='red' />}
                {data.reason === null && <ListItemText primary={`N/A`}/>}
            </ListItem>
        </Card>
    )
}