import {gql} from "@apollo/client";

export const jediAllPilotsQuery = gql`
    query{
      jediAllPilots {
        pilots{
          userKey
          portfolioKey
          title
          description
          sourceDescription
          profileImageUrl
          profileImageLargeUrl
          alternateProfileImageLargeUrl
          cardImageUrl
          subtitle
          tradeDelayTime
          yearlyAverage
          currentYear
          previousYear
          yearsTrading
          nbrTradesPerMonth
          maxCashPositionAmount
          priority
          remoteGhostSlug
        }
      }
    }`;

export interface AllPilotsData {
    userKey: number;
    portfolioKey: number;
    title: string;
    description: string;
    sourceDescription: string;
    profileImageUrl: string;
    profileImageLargeUrl: string;
    alternateProfileImageLargeUrl: string;
    cardImageUrl: string;
    subtitle: string;
    tradeDelayTime: string;
    yearlyAverage: number;
    currentYear: number;
    previousYear: number;
    yearsTrading: number;
    nbrTradesPerMonth: number;
    maxCashPositionAmount: number;
    priority: number;
    remoteGhostSlug: string;
}