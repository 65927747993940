import { gql } from '@apollo/client';

export const pilotTriggerHistoryQuery = gql`
    query triggerHistoryGet(
        $userKey: Int!
        $autoPilotSettingsKey: Int
        $limit: Int!
        $offset: Int!
    ) {
        triggerHistoryGet(input: {
            userKey: $userKey
            autoPilotSettingsKey: $autoPilotSettingsKey
            limit: $limit
            offset: $offset
        }) {
            triggerHistoryKey
            type
            transactionAmount
            autoPilotSettingsKey
            tradeCount
            createdAt
            title
            triggerStatus
            triggerApprovalStatus
            equilibriumAchievedAt
            approvedAt
            lastSuppressedAt
            lastSuppressionReason
            lastPendingNextCycleAt
            masterPortfolio {
                portfolioName
            }
            triggerHistoryOrders {
                asset {
                    assetKey
                    pictureUrl
                }
                symbol
                orderSide
                quantity
                averagePrice
                orderState
                orderStatus
                fullfilledAt
                transactionAmount
                failureReason
            }
        }
    }
`;

export const jediAllocationAdjustmentHistoryGet = gql`
    query($autoPilotSettingsKey: Int
          $userKey: Int)
      {
      jediAllocationAdjustmentHistoryGet(input: {autoPilotSettingsKey: $autoPilotSettingsKey, userKey: $userKey}) {
        allocationAdjustments{
          autoPilotSettingsKey
          autoPilotSettingsAllocationAdjustmentKey
          oldAllocationAmount
          newAllocationAmount
          amountDeficient
          createdAt
        }
      }
    }
`;

export const triggerHistoryOrdersQuery = gql`
  query triggerHistoryOrdersGet(
        $autoPilotSettingsKey: Int!, 
        $triggerHistoryKey: Int!
    ) {
        triggerHistoryOrdersGet (
            input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
            triggerHistoryKey: $triggerHistoryKey
        }
    ) {
            asset {
                assetKey
                pictureUrl
            }
            symbol
            orderSide
            quantity
            averagePrice
            orderState
            orderStatus
            fullfilledAt
            transactionAmount
            failureReason
        }
    }
`;

export interface TriggerHistoryItem {
    triggerHistoryKey: number;
    type: string;
    transactionAmount: number;
    tradeCount: number;
    createdAt: Date;
    title: string;
    triggerStatus: string;
    triggerApprovalStatus: string;
    masterPortfolio: {
        portfolioName: string;
    } | null;
    triggerHistoryOrders: TriggerHistoryOrdersItem[] | null;
    typeColor: string;
    equilibriumAchievedAt: string;
    lastSuppressedAt: string;
    lastSuppressionReason: string;
    lastPendingNextCycleAt: string;
}

export interface AllocationAmountItem {
    autoPilotSettingsKey: number;
    autoPilotSettingsAllocationAdjustmentKey: string;
    oldAllocationAmount: number;
    newAllocationAmount: number;
    amountDeficient: number;
    createdAt: Date;
}

export interface TriggerHistoryOrdersItem {
    asset: {
        assetKey: number;
        pictureUrl: string;
    };
    symbol: string;
    orderSide: string;
    quantity: number;
    averagePrice: number;
    orderState: string;
    orderStatus: string;
    fullfilledAt: string;
    transactionAmount: number;
    failureReason: string;
}

export interface CustomTriggerHistoryOrdersItem {
    asset: {
        assetKey: number;
        pictureUrl: string;
    };
    symbol: string;
    orderSide: string;
    quantity: number;
    averagePrice: number;
    orderState: string;
    orderStatus: string;
    fullfilledAt: string;
    transactionAmount: number;
    failureReason: string;
    createdAt: Date;
}