import { gql } from '@apollo/client';


export const whoisit = gql`
    query whoisit(
        $portfolioKey: Int
        $userKey: Int
        $autoPilotSettingsKey: Int
        $institutionKey: Int
    ) {
        whoisit(input: {
        portfolioKey: $portfolioKey
        userKey: $userKey
        autoPilotSettingsKey: $autoPilotSettingsKey
        institutionKey: $institutionKey
    }) {
        user {
            userKey
            firstName
            lastName
            deletedAt
        }
        institution {
            institutionKey
        }
    }
  }
`;