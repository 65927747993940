import { gql } from '@apollo/client';
import { BROKER_NAME, CONNECTION_STATUS } from '../../../shared/portfolio-types';

export const jediPortfolioPositionsGet = gql`
    query jediPortfolioPositionsGet(
        $portfolioKey: Int!
    ) {
        jediPortfolioPositionsGet(input: {
            portfolioKey: $portfolioKey
        }) {
            positions {
                portfolioKey
                portfolio {
                    brokerName
                    connectionStatus
                }
                symbol
                remotePosition {
                    averagePrice
                    quantity
                }
                localPosition {
                    averagePrice
                    quantity
                }
                manualPosition {
                    averagePrice
                    quantity
                }
                pilotedPositions {
                    averagePrice
                    quantity
                    pilotName
                    autoPilotSettingsKey
                }
            }
        }
    }
`;

export interface JediPortfolioPositionsGetResponse {
    jediPortfolioPositionsGet: {
        positions: JediPortfolioPosition[];
    };
};

export interface JediPortfolioPosition {
    portfolioKey: string;
    portfolio: {
        brokerName: BROKER_NAME;
        connectionStatus: CONNECTION_STATUS;
    };
    symbol: string;
    remotePosition: {
        averagePrice: number;
        quantity: number;
    };
    localPosition: JediIndividualPosition;
    manualPosition: JediIndividualPosition;
    pilotedPositions: JediIndividualPosition[];
};

export interface JediIndividualPosition {
    averagePrice: number;
    quantity: number;
    pilotName: string;
    autoPilotSettingsKey: string;
};