import { useQuery, useMutation } from '@apollo/client';

/**
 * 
 * Usage of hook:
 * 
  const { loading, error, data } = useQueryAsUser(GQL_QUERY, { 
    userKey: 1,
    variables: {
      ...
    }
  });
 * 
 */

export function useQueryCoordinate(query, options) {
  const { variables } = options;
  return useQuery(query, {
    ...options,
    variables,
    context: {
      headers: {
        ...options.context?.headers,
        coordinate: process.env.REACT_APP_COORDINATE
      }
    }
  });
}

export function useMutationCoordinate(mutation, options) {
  const { variables } = options;

  return useMutation(mutation, {
    ...options,
    variables,
    context: {
      ...options.context,
      headers: {
        ...options.context?.headers,
        coordinate: process.env.REACT_APP_COORDINATE
      }
    }
  });
}

export function useQueryAsUser(query, options) {
  const { variables, userKey, appVersionEnabled } = options;

  return useQuery(query, {
    ...options,
    fetchPolicy: options.fetchPolicy || 'network-only',
    variables,
    context: {
      headers: {
        ...options.context?.headers,
        onBehalfOfUserKey: userKey || '',
        coordinate: process.env.REACT_APP_COORDINATE,
        'x-app-version' : appVersionEnabled? process.env.REACT_APP_VERSION : ''
      }
    }
  });
}

export function useMutationAsUser(mutation, options) {
  const { variables, userKey, appVersionEnabled } = options;

  return useMutation(mutation, {
    ...options,
    variables,
    context: {
      ...options.context,
      headers: {
        ...options.context?.headers,
        onBehalfOfUserKey: userKey || '',
        coordinate: process.env.REACT_APP_COORDINATE,
        'x-app-version': appVersionEnabled? process.env.REACT_APP_VERSION : ''
      }
    }
  });
}