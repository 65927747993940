export class AuthClient {

  constructor(clientBase) {
    this.client = clientBase;
  }

  async authPing() {
    return this.client.sendRequest(
      'GET',
      '/',
      {}
    );
  }

}