import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export function IconLoader({inputSize, text, fullscreen}) {

  const DEFAULT_SIZE = 100;

  const size = inputSize || DEFAULT_SIZE;
  let sizing={ width: '100vw' };
  if (fullscreen) {
    sizing['height'] = '100vh';
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={sizing}
    >
      <Box>
        <center>
          <SpinningLogo src="/favicon.png" alt="Spinning" height={size + 'px'} />
          <br />
          <Typography fontSize={size / 5}>{text}</Typography>
        </center>
      </Box>
    </Box>
  );
}

export const SpinningLogo = styled('img')(() => ({
  animation: 'spin-pause infinite 1s ease-in-out', // Total duration includes spin and pause
  '@keyframes spin-pause': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '80%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
