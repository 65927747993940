import { Box, Switch, Typography } from "@mui/material"
import { IS_PROD } from "../../clients/ClientRegistry"

export function TestProdToggle() {

  const onSwitchChange = () => {
    if (IS_PROD) {
      window.location.href = 'https://dev.jedi.joinautopilot.com';
    } else {
      window.location.href = 'https://jedi.joinautopilot.com';
    }
  }

  return <Box>
    <Typography component="span" sx={{ color: IS_PROD ? 'white' : 'orange' }}>Test Mode</Typography>
    <Switch defaultChecked={!IS_PROD} color={"warning"} onChange={onSwitchChange}/>
  </Box>
}