import {DataGrid, GridColDef, GridRenderCellParams, GridRowParams} from '@mui/x-data-grid';
import {
    AllocationAmountItem,
    jediAllocationAdjustmentHistoryGet,
    pilotTriggerHistoryQuery,
    TriggerHistoryItem
} from "../../clients/graphql/user-pilots/pilot-trigger-history.query";
import {useQueryAsUser, useQueryCoordinate} from '../../clients/graphql/graphql.hooks';
import BuyIcon from '@mui/icons-material/TrendingUp';
import SellIcon from '@mui/icons-material/TrendingDown';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import {CircularProgress, Dialog, Tooltip, Typography} from '@mui/material';
import {TriggerHistoryOrdersTable} from './TriggerHistoryOrdersTable';
import React, {useState} from 'react';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import _ from 'lodash';
import moment from 'moment';
import BoltIcon from '@mui/icons-material/Bolt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
const buys = ['RECURRING_INVESTMENT', 'MANUAL_DEPOSIT'];
const sells = ['MANUAL_WITHDRAW', 'MASTER_DELETED'];
const trades = ['MASTER_TRADED'];
const rebalance = ['REBALANCE'];
const allocationAdjustment = ['ALLOCATION_ADJUSTMENT'];

/*
* Total deposits will be a summation of all the completed buys
* */
export function totalDeposits(rows: TriggerHistoryItem[]) {
    let total = 0;

    for(let row of rows){
        if(buys.includes(row.type) && row.equilibriumAchievedAt != null){
            total += row.transactionAmount;
        }
    }
    return(
        total
    );
}



const renderCellWithIcon = (params: GridRenderCellParams) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', color: params.row.typeColor }}>
        {buys.includes(params.row.type) && <BuyIcon />}
        {sells.includes(params.row.type) && <SellIcon />}
        {trades.includes(params.row.type) && <AlertIcon />}
        {rebalance.includes(params.row.type) && <AutorenewOutlinedIcon />}
        {allocationAdjustment.includes(params.row.type) && <BoltIcon />}
        <span>{params.value.split('_').map(_.capitalize).join(' ')}</span>
    </div>
);

function timeDif(start: moment.Moment, end: moment.Moment){
    const marketOpen = moment().set({ hour: 6, minute: 30, date: end.date(), month: end.month()});

    if(start.day() === end.day()){
        const rv = moment.duration(end.diff(start));
        return `${rv.hours()}h ${rv.minutes()}m`;
    }

    else {
        const totalMilliseconds = end.diff(start); // time including market
        const closedMillis = marketOpen.diff(start); // the time between the trade request and market actually opening

        const timeToTrade = totalMilliseconds - closedMillis; // excluding time when market was closed

        const totalSeconds = timeToTrade / 1000;
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor(totalSeconds % 3600) / 60

        let returnString = `${hours}h ${minutes.toFixed(0)}m`;

        if (returnString === `NaNh NaNm`){
            returnString = ``;
        }
        return (
            returnString
        );
    }
}

const dateFormattingOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
};

function renderDate(checkTimeAndDate: any) {
    if (!checkTimeAndDate) {
        return '';
    }
    return new Date(checkTimeAndDate).toLocaleDateString(
        'en-US',
        dateFormattingOptions,
    );
}

const columns: GridColDef[] = [
    {
        headerName: 'Status',
        field: 'equilibriumAchievedAt',
        headerClassName: 'table-header',
        headerAlign: 'center',
        description: 'Is equilibrium achieved',
        minWidth: 80,
        flex: 1,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: "100%"}}>
                    {params.value == null ? (
                        <PendingIcon sx={{color: 'orange'}}></PendingIcon>
                    ) : (
                        <Tooltip title={"Completed at "+moment(params.value).format('MM/DD/YY HH:mm:ss')}>
                            <CheckCircleIcon color={'success'}></CheckCircleIcon>
                        </Tooltip>
                    )}
                </div>
            );
        }
    },
    {
        headerName: 'Trigger',
        field: 'type',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 150,
        description: 'The type of trigger',
        renderCell: renderCellWithIcon,
    },
    {
        headerName: 'Amount',
        field: 'transactionAmount',
        headerAlign: 'center',
        align: 'center',
        minWidth: 100,
        flex: 1,
        description: 'Total dollar amount transacted',
        type: 'number',
        valueFormatter: (value: number)=> value ? `$${value.toFixed(2)}` : '',
    },
    {
        headerName: 'Invested',
        headerAlign: 'center',
        field: 'totalInvested',
        valueFormatter: (value) => value ? `$${value}` : '',
        minWidth: 120,
        flex: 1,
        description: 'Net Amount Invested',
        type: 'number',
        align: 'center',
    },
    {
        headerName: 'Trades',
        field: 'tradeCount',
        minWidth: 70,
        flex: 1,
        description: 'Number of trades executed',
        type: 'number',
        headerAlign: 'center',
        align: 'center',
    },
    {
        headerName: 'Date',
        field: 'createdAt',
        minWidth: 200,
        flex: 1,
        description: 'When the trigger was executed',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            return(
                renderDate(params.value)
            );
        },
    },
    {
        headerName: 'Duration',
        field: 'timeDifference',
        minWidth: 100,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        description: 'Time between initial request and final filled trade',
        renderCell: (params: GridRenderCellParams) => {
            const equilibriumAchievedAt = moment(params.row.equilibriumAchievedAt);
            const createdAt = moment(params.row.createdAt);
            const durationtwo = timeDif(createdAt, equilibriumAchievedAt);

            // Format the output
            return (
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                    <Typography fontSize={'small'}>
                        {durationtwo}
                    </Typography>
                </div>
            );

        },
    },
    {
        headerName: 'Pilot',
        field: 'portfolioName',
        minWidth: 110,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        description: 'The pilot that executed the trigger',
        renderCell: params => {
            // const trimmedPilot = params.value.replace(/tracker/gi, '').trim();
            return(

                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography style={{fontSize: 'small'}}>
                    {params.value}
                </Typography></div>
            )
        }
    },
    {
        headerName: 'Suppression Reason',

        field: 'lastSuppressionReason',
        minWidth: 150,
        description: 'The last suppression reason',
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            {params.row.equilibriumAchievedAt == null ? (
                                <Typography fontSize={'small'}>
                                    {params.value}
                                </Typography>
                            ) : (
                                ''
                            )}
                        </div>
                    )
        }
    },
    {
        headerName: 'Last Suppressed At',
        field: 'lastSuppressedAt',
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
            const formattedDate = moment(params.value);
            const cellDisplay = formattedDate.isValid() ? formattedDate.format('MM/DD/YY HH:mm:ss') : '';
            return (
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                    {params.row.equilibriumAchievedAt == null ? (
                            <span>{cellDisplay}</span>
                    ) : (
                        ''
                    )}
                </div>
            );

        }
    },
    {
        headerName: 'Approved At',
        field: 'approvedAt',
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        flex: 1,
        description: 'Date when transaction was approved',
        renderCell: (params) => {
            if(params.value){
                const formattedDate = moment(params.value);
                const cellDisplay = formattedDate.isValid() ? formattedDate.format('MM/DD HH:mm') : '';
                const hoverDisplay = formattedDate.isValid() ? formattedDate.format('MM/DD/YY HH:mm:ss') : '';
                return (
                    <Tooltip title={hoverDisplay}>
                        <span>{cellDisplay}</span>
                    </Tooltip>
                );
            }
        },
    }
];

interface TriggerHistoryTableProps {
    info: {
        userKey?: number;
        autoPilotSettingsKey: number;
        allocationAmount: number;
        isUserView: boolean;
    }
}



export const TriggerHistoryTable: React.FC<TriggerHistoryTableProps> = ({ info }) => {
    const { userKey, autoPilotSettingsKey, isUserView } = info;
    const [selectedRow, setSelectedRow] = useState<TriggerHistoryItem | null>(null);
    const handleRowClick = (params: GridRowParams) => {
        const selectedTrigger = details.find((item) => item.triggerHistoryKey === params.row.triggerHistoryKey);
        if (selectedTrigger) {
            setSelectedRow(selectedTrigger);
        }
    };

    const queryVariables = autoPilotSettingsKey === undefined ? { userKey, limit: 100, offset: 0 } : { userKey, autoPilotSettingsKey, limit: 100, offset: 0 };

    const { data, loading, error } = useQueryAsUser(pilotTriggerHistoryQuery, { userKey, variables: queryVariables });
    const {data: allocationData, loading: allocationLoading, error: allocationError} = useQueryCoordinate(jediAllocationAdjustmentHistoryGet, { variables: isUserView? { autoPilotSettingsKey, userKey } : { autoPilotSettingsKey }})

    const theme = useTheme();

    if(loading || allocationLoading) {
        return  (
            <CircularProgress size={60} sx={{ marginTop: '80px', marginLeft: '50%'}} />
        );
    }

    if(error || allocationError) return <div>Error: {error?.message}{allocationError?.message}</div>;
    const details: TriggerHistoryItem[] = data?.triggerHistoryGet.map((item: TriggerHistoryItem) => ({
        ...item,
        typeColor: buys.includes(item.type) ? theme.palette.success.main  : sells.includes(item.type) ? 'orange' : theme.palette.primary.main,
    })) ?? [];

    let rows = details.map((row, index) => {
            return {
                ...row,
                id: index,
                triggerStatus: row.triggerStatus, // symbolsWithOnlyFailures.length > 0 ? 'FAILED' : pendingTrades.length > 0 ? 'PENDING' : 'COMPLETE',
                portfolioName: row.masterPortfolio?.portfolioName,
                totalInvested: _.round(_.sumBy(row.triggerHistoryOrders, (order) => {
                    if (order.orderState === 'FILLED') {
                        return order.orderSide === 'BUY' ? order.transactionAmount : -order.transactionAmount;
                    } else {
                        return 0;
                    }
                }), 2),
            }
    });

    allocationData?.jediAllocationAdjustmentHistoryGet?.allocationAdjustments?.forEach((item: AllocationAmountItem) => {

        const amount = item.newAllocationAmount - item.oldAllocationAmount;
        if (amount !== 0) {
            rows.push({
                    id: parseInt(item.autoPilotSettingsAllocationAdjustmentKey),
                    triggerStatus: 'MANUAL_ADJUSTMENT',
                    portfolioName: '',
                    transactionAmount: amount,
                    createdAt: item.createdAt,
                    totalInvested: 0,
                    triggerHistoryKey: 0,
                    tradeCount: 0,
                    title: '',
                    triggerApprovalStatus: '',
                    masterPortfolio: null,
                    triggerHistoryOrders: null,
                    type: 'ALLOCATION_ADJUSTMENT',
                    typeColor: 'red',
                    equilibriumAchievedAt: 'N/A',
                    lastSuppressionReason: '',
                    lastSuppressedAt: '',
                    lastPendingNextCycleAt: '',
                }
            )
        }
    });


    return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '32rem',}}>
            <Typography variant="subtitle1" component="div" align="left"
                        sx={{
                            color: '#6D6D6D',
                            font: 'Roboto',
                            fontWeight: 500,
                            fontSize: '13px',
                            lineHeight: '12px',
                            letterSpacing: '0.05em',
                            paddingBottom: '.75rem',
                        }}>
                TRANSACTIONS
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                rowCount={data?.triggerHistoryGet.length ?? 0}
                loading={loading}
                onRowClick={handleRowClick}
                rowHeight={40}
                hideFooter={true}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'createdAt', sort: 'desc',}],
                    },
                }}
            />
            {selectedRow && (
                <Dialog
                    open={true}
                    onClose={() => setSelectedRow(null)}
                    fullWidth={true}
                    maxWidth="lg"
                    PaperProps={{
                        style: {
                            maxHeight: '80vh',
                            overflow: 'auto',
                        },
                    }}
                >
                    <TriggerHistoryOrdersTable
                        createdAt={selectedRow.createdAt}
                        // props={details.find(d => d.triggerHistoryKey === selectedRow.triggerHistoryKey)?.triggerHistoryOrders || []}
                        props={details.find(d => d.triggerHistoryKey === selectedRow.triggerHistoryKey)?.triggerHistoryOrders?.map(order => ({
                                ...order,
                                createdAt: selectedRow?.createdAt
                            })) || []}
                        targetAmount={selectedRow.transactionAmount}
                        rows={rows}
                    />
                </Dialog>
            )}
        </Box>
    );
};
