import Box from "@mui/material/Box";
import {Card, Divider, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import PaidIcon from "@mui/icons-material/Paid";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import BackupIcon from "@mui/icons-material/Backup";
import moment from "moment";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import React from "react";

interface props{
    data: brokerACHDetails
}

interface brokerACHDetails{
    amount: string,
    amountOnHold: string,
    status: string,
    earlyAccessAmount: string,
    submittedAt: string,
    settledAt: string,
    createdAt: string,
}


export default function PortfolioEventBrokerACHDetails ({data} : props) {
    return (
        <Card elevation={4} sx={{marginTop: 2}}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
                <br />
                <Typography variant="h6" component='div' >
                    Broker ACH Details
                </Typography>
                <Box sx={{ flexGrow: 2 }} />
            </Box>
            <Divider sx={{ my: 1 }} />
            <ListItem sx={{marginTop: -1.5, marginBottom: -1.5}}>
                <ListItemIcon>
                    <HourglassFullIcon />
                </ListItemIcon>
                Status:&nbsp;
                {data.status === 'COMPLETED' && <p style={{ color: 'green'}}>{data.status}</p>}
                {data.status === 'PENDING' && <p style={{color: '#FFCC00', fontWeight: 'bolder'}}>{data.status}</p>}
                {data.status === 'CANCELLED' && <p style={{color: 'red', padding: 'none', fontWeight: 'bolder'}}>{data.status}</p>}
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <PaidIcon />
                </ListItemIcon>
                <ListItemText primary={`Amount: ${parseInt(data.amount)?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <BackupIcon />
                </ListItemIcon>
                <ListItemText primary={`Submitted At: ${moment(data.submittedAt).format('MM/DD/YYYY HH:mm')}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <StopCircleIcon />
                </ListItemIcon>
                <ListItemText primary={`Amount On Hold: ${parseInt(data.amountOnHold)?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <AccessTimeFilledIcon />
                </ListItemIcon>
                <ListItemText primary={`Early Access Amount: ${parseInt(data.earlyAccessAmount)?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })}`} />
            </ListItem>

            <Divider sx={{ my: 1 }} />
            <ListItem>
                <ListItemIcon>
                    <ExpandCircleDownIcon />
                </ListItemIcon>
                <ListItemText primary={`Settled At: ${moment(data.settledAt).format('MM/DD/YYYY HH:mm')}`} />
            </ListItem>
            <Divider sx={{ my: 1 }} />
            <ListItem sx={{marginBottom: 1}}>
                <ListItemIcon>
                    <PlayCircleFilledIcon />
                </ListItemIcon>
                <ListItemText primary={`Created At: ${moment(data.createdAt).format('MM/DD/YYYY HH:mm')}`} />
            </ListItem>
        </Card>
    )
}