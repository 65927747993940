import { gql } from "@apollo/client";
import { BROKER_NAME, CONNECTION_STATUS } from "../../../shared/portfolio-types";

export const userPilotDetailsQuery = gql`
    query slaveAutoPilotPortfoliosGet (
        $autoPilotSettingsKey: Int!
    ) {
        slaveAutoPilotPortfoliosGet( input:{
            autoPilotSettingsKey: $autoPilotSettingsKey
            setupStatuses: [DELETED, INITIATED, ACTIVE, PAUSED, SHORT_CIRCUITED]
        }) {
            totalValue
            totalProfitLoss
            totalProfitLossPercent
            slaveAutoPilotPortfolios {
                autoPilotSettingsKey
                masterPortfolio {
                    userFullName
                }
                slaveUserKey
                autoPilotSettings {
                    allocationAmount
                    initiatedAt
                    setupStatus
                    amountPending
                    slavePortfolio {
                        portfolioKey
                        portfolioName
                        brokerName
                        connectionStatus
                    }
                }
                currentPositions {
                    symbol
                    name
                    pictureUrl
                    quantity
                    percentOfPortfolio
                    marketValue
                    currentPrice
                    assetKey
                }
                brokerName
                connectionStatus
                isManualExecution
            }
        }
    }
`;

export const getSlavePortfoliosQuery = gql`
    query getSlavePortfolios  {
        slaveAutoPilotPortfoliosGet( 
            input:{
            setupStatuses: [INITIATED, ACTIVE, SHORT_CIRCUITED]
        }
    ) {
            totalValue
            totalProfitLoss
            totalProfitLossPercent
            slaveAutoPilotPortfolios {
                totalValue
                autoPilotSettingsKey
                masterPortfolio {
                    userFullName
                    portfolioCardImageUrl
                }
                slaveUserKey
                autoPilotSettings {
                    allocationAmount
                    initiatedAt
                    setupStatus
                    amountPending
                    slavePortfolio {
                        portfolioKey
                        portfolioName
                        brokerName
                        connectionStatus
                    }
                }
                pendingTransferData{
                    amount
                    submittedAt
                }
                failedTransferData{
                    amount
                    submittedAt
                }
                currentPositions {
                    symbol
                    name
                    pictureUrl
                    quantity
                    percentOfPortfolio
                    marketValue
                    currentPrice
                    unrealizedProfitLoss
                    unrealizedProfitLossPercent
                    assetDescription
                    assetKey
                }
                autoPilotCashHolding {
                    marketValue
                    assetDescription
                    name
                    percentOfPortfolio
                    symbol
                }
                brokerName
                connectionStatus
                isManualExecution
            }
        }
    }
`;

export interface UserPilotDetailsQueryData {
    totalValue: number;
    totalProfitLoss: number;
    totalProfitLossPercent: number;
    slaveAutoPilotPortfolios: SlaveAutoPilotPortfolio[];
}

export interface SlaveAutoPilotPortfolio {
    slaveUserKey: number;
    autoPilotSettingsKey: number;
    masterPortfolio: {
        userFullName: string;
        portfolioCardImageUrl: string;
    };
    autoPilotSettings: AutoPilotSettings;
    currentPositions: CurrentPosition[];
    failedTransferData: TransferData;
    pendingTransferData: TransferData;
    autoPilotCashHolding: AutoPilotCashHolding;
    brokerName: string;
    connectionStatus: string;
    isManualExecution: boolean;
}
export interface AutoPilotCashHolding{
    marketValue: number;
    symbol: string;
    percentOfPortfolio: number
    assetDescription: string
    name: string
}
export interface TransferData {
    amount: number;
    submittedAt: Date;
}
export interface AutoPilotSettings {
    allocationAmount: number;
    setupStatus: string;
    amountPending: number;
    slavePortfolio: SlavePortfolio;
    initiatedAt: Date;
}

export interface SlavePortfolio {
    portfolioKey: number;
    portfolioName: string;
    brokerName: BROKER_NAME;
    connectionStatus: CONNECTION_STATUS;
}

export interface CurrentPosition {
    symbol: string;
    name: string;
    pictureUrl: string;
    quantity: number;
    percentOfPortfolio: number;
    marketValue: number;
    currentPrice: number;
    assetKey: string;
    unrealizedProfitLossPercent: number
    unrealizedProfitLoss: number
    assetDescription: string
}