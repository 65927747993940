import { gql } from '@apollo/client';

export const jediUserLookup = gql`
    query jediUserLookup(
        $searchValue: String!
    ) {
        jediUserLookup(input: {
            searchValue: $searchValue,
            limit: 10
        }) {
            results {
                userKey
                phoneNumber
                email
                firstName
                lastName
            }
        }
    }
`;