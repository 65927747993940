import { Box, Typography } from '@mui/material';
import { ListEntityTable } from '../../components/tables/ListEntityTable';


export function ListUsersPage() {
  return <Box>
    <Typography variant="h4">Users</Typography>
    <ListEntityTable 
      entity="userEntity" 
      idField="userKey"
      rowToUrl={row => `/users/${row.userKey}`}
      columns={[
        { field: 'userKey'},
        { field: 'phone'},
        { field: 'email'},
        { field: 'firstName'},
        { field: 'lastName'},
      ]}
    />
  </Box>;
}