import { Button } from "@mui/material";

export type ZendeskUserButtonProps = {
  userId: number
}

export function ZendeskUserButton({ userId }: ZendeskUserButtonProps) {
  return <Button 
    component="a"
    target="_blank"
    rel="noopener noreferrer"
    href={`https://joinautopilotsupport.zendesk.com/agent/users/${userId}/requested_tickets`}
    variant="outlined"
    size="small"
    color={'success'}
  >
      Zendesk
  </Button>;
}