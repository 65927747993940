import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import {UserPilotDetailsOverview, UserPilotOverviewData} from '../../views/pilots/UserPilotDetailsOverview';
import {
    SlaveAutoPilotPortfolio,
    userPilotDetailsQuery,
} from '../../clients/graphql/user-pilots/user-pilot-details.query';
import {useQueryAsUser, useQueryCoordinate} from '../../clients/graphql/graphql.hooks';
import {TriggerHistoryTable} from '../../components/tables/TriggerHistoryTable';
import {LegacyAdminButton} from '../../components/buttons/LegacyAdminButton';
import {BalancingDecisionsTable} from '../../components/tables/BalancingDecisionsTable';
import {FailedAutopilotOrdersTable} from '../../components/tables/FailedAutopilotOrdersTable';
import {whoisit} from '../../clients/graphql/user/whoisit.query';
import {BalancePortfolioButton} from "../../components/buttons/BalancePortfolioButton";
import {PortfolioRestrictionsTable} from '../../components/tables/PortfolioRestrictionsTable';

const mappedData = (details: SlaveAutoPilotPortfolio[]) => {
    const mapped: UserPilotOverviewData = details.map((detail: SlaveAutoPilotPortfolio) => ({
        userKey: detail.slaveUserKey,
        autoPilotSettingsKey: detail.autoPilotSettingsKey,
        pilot: detail.masterPortfolio.userFullName,
        allocation: detail.autoPilotSettings.allocationAmount,
        status: detail.autoPilotSettings.setupStatus,
        portfolio: {
            brokerName: detail.autoPilotSettings.slavePortfolio.brokerName,
            connectionStatus: detail.autoPilotSettings.slavePortfolio.connectionStatus,
            portfolioKey: detail.autoPilotSettings.slavePortfolio.portfolioKey,
        },
        portfolioConnectionState: detail.autoPilotSettings.slavePortfolio.connectionStatus,
        amountPending: detail.autoPilotSettings.amountPending,
        initiatedAt: detail.autoPilotSettings.initiatedAt,
        equilibriumState: '',
        isManualExecution: detail.isManualExecution
    }))[0];
    return mapped;
}

export const UserPilotDetailsPage: React.FC = () => {
    const {autoPilotSettingsKey} = useParams() || '';
    const whoisitResponse = useQueryCoordinate(whoisit, {variables: {autoPilotSettingsKey: parseInt(autoPilotSettingsKey as string)}});
    const userKey = whoisitResponse.data?.whoisit?.user?.userKey;
    const parsedSettingsKey = parseInt(autoPilotSettingsKey as string);
    const {data, loading, error} = useQueryAsUser(userPilotDetailsQuery, {
        userKey,
        autoPilotSettingsKey: parsedSettingsKey,
        variables: {userKey, autoPilotSettingsKey: parsedSettingsKey}
    });
    if (loading || !userKey) return <CircularProgress size={60} sx={{ marginTop: '80px', marginLeft: '50%'}} />;
    if (error) return <div>Error: {error.message}</div>;
    const details = data?.slaveAutoPilotPortfoliosGet?.slaveAutoPilotPortfolios ?? [];
    const mapped = mappedData(details);

    if (!mapped) return <div>No data</div>;
    return <Box>
        <Typography variant="h4" paddingBottom={2}>{mapped.pilot} </Typography>
        <Box display={'flex'} gap={1}>
            <LegacyAdminButton path={`/slaves/${parsedSettingsKey}`}/>
            <BalancePortfolioButton autoPilotSettingsKey={parsedSettingsKey} />
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={2.5}>
                <UserPilotDetailsOverview data={mapped}/>
            </Grid>
            <Grid item xs={9.5}>
                    <BalancingDecisionsTable
                        userKey={parseInt(userKey as string)}
                        autoPilotSettingsKey={parseInt(autoPilotSettingsKey as string)}
                        positions={details[0].currentPositions}
                        portfolioDetails={{portfolioKey: mapped.portfolio.portfolioKey, brokerName: mapped.portfolio.brokerName, connectionStatus: mapped.portfolio.connectionStatus}}
                        status={mapped.status}
                    />
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{marginBottom: '1rem', paddingTop: '2rem'}}>
                <TriggerHistoryTable info={{
                    userKey: parseInt(userKey as string),
                    autoPilotSettingsKey: parsedSettingsKey,
                    allocationAmount: mapped.allocation,
                    isUserView: false,
                }}/>

            <Grid item xs={6} sx={{marginBottom: '1rem', paddingTop: '1rem'}}>
                    <FailedAutopilotOrdersTable sx={{marginBottom: '1rem', paddingTop: '.5rem'}} autoPilotSettingsKey={parseInt(autoPilotSettingsKey as string)}/>
            </Grid>
            <Grid item xs={6}>
                    <PortfolioRestrictionsTable userKey={parseInt(userKey as string)} portfolioKey={mapped.portfolio.portfolioKey} />
            </Grid>
        </Grid>
       
    </Box>;
};
