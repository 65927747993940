import { gql } from '@apollo/client';

export const institutionAuthActivitiesGet = gql`
    query institutionAuthActivitiesGet(
        $institutionKey: Int!
        $limit: Int!
        $offset: Int!
    ) {
        institutionAuthActivitiesGet(input: {
            institutionKey: $institutionKey
            limit: $limit
            offset: $offset
        }) {
            institutionAuthActivities {
                activityType
                createdAt
                note
                success
                data
            }
        }
    }
`;

export interface institutionAuthActivity {
    activityType: string;
    createdAt: string;
    note: string;
    success: boolean;
    data: any;
};