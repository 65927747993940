import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { entityClient } from '../../clients/ClientRegistry';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EntityFilter } from '../../clients/http/EntityClient';

export function ListEntityTable({
  title,
  entity,
  idField,
  columns,
  rowToUrl,
  pinField,
  pinValue
}) {

  const goTo = useNavigate();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(15);

  async function loadRows() {
    setLoading(true);
    const [, data] = await entityClient.listEntity(entity, page,
      pinField && pinValue && [
        new EntityFilter().withField(pinField).eq().value(pinValue)
      ]
    );
    setRows(data.rows);
    setTotalRows(data.count);
    setLoading(false);
  }

  useEffect(() => {
    loadRows();
  }, [page]);

  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <DataGrid
        getRowId={x => x[idField]}
        rows={rows}
        rowCount={totalRows}
        columns={columns.map(c => {
          c.sortable = false;
          c.filterable = false;
          return c;
        })}
        loading={loading}
        autoHeight={true}
        paginationModel={{
          page: page,
          pageSize: pageSize
        }}
        pageSizeOptions={[15]}
        paginationMode='server'
        onPaginationModelChange={(newModel) => {
          setPageSize(newModel.pageSize);
          setPage(newModel.page);
        }}
        rowHeight={30}
        onRowClick={(event) => {
          if (rowToUrl) {
            goTo(rowToUrl(event.row));
          }
        }}
        sx={{
          // disable cell selection style
          '.MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          // pointer cursor on ALL rows
          '& .MuiDataGrid-row:hover': {
            cursor: rowToUrl ? 'pointer' : 'none'
          }
        }}
      />
    </Box>
  );
}