import {useNavigate} from "react-router-dom";

export type StockSymbolComponentProps = {
    logo: string,
    symbol: string,
    value: string
}

export function StockSymbolComponent({ logo, symbol, value }: StockSymbolComponentProps) {

    let goTo = useNavigate();

    return (

        <div style={{display: 'flex', alignItems: 'left', height: '100%'}} onClick={() => goTo('/assets/' + symbol)}>
            <img src={logo} alt="Logo"
                 style={{width: 'auto', height: '30px', borderRadius: '50%', alignSelf: 'center'}}/>
            <span style={{marginLeft: 10}}>{value}</span>
        </div>

    );
}