import {InstitutionHealthTile} from './InstitutionHealthTile'
import {TriggerTypeTile} from "./TriggerTypeTile";
export default function Home () {

    return (
        <>
            <InstitutionHealthTile />
            <TriggerTypeTile />
        </>
    )

}