export class ClientBase {

  constructor(
    api,
    tokenContainer
  ) {
    this.api = api;
    this.tokenContainer = tokenContainer;
  }

  async sendRequest(method, endpoint, payload) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open(method, `${this.api}${endpoint}`, true);
      xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/json');
      if (this.tokenContainer.hasToken()) {
        const token = this.tokenContainer.getToken();
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          const data = xhr.response ? JSON.parse(xhr.response) : null;
          if (xhr.status === 401) {
            window.location.href = this.getLoginRedirect(); // Smooth redirect can be handled here if needed
            reject('Unauthorized');
          } else if (xhr.status === 403) {
            window.location.href = this.getForbiddenRedirect(); // Smooth redirect can be handled here if needed
            reject('Forbidden');
          } else {
            resolve([xhr.status, data]);
          }
        }
      };
      xhr.send(JSON.stringify(payload));
    });
  }

  getLoginRedirect() {
    return '/login?redirect=' + encodeURIComponent(window.location.href);
  }

  getForbiddenRedirect() {
    return '/forbidden';
  }
}