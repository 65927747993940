import Box from "@mui/material/Box";
import {Grid,} from "@mui/material";
import React from "react";
import {AllPilotsTable} from "../../components/tables/AllPilotsTable";

export function ViewPilotsPage() {
    return <Box>
        <Grid item xs={6}>
            <AllPilotsTable />
        </Grid>
    </Box>
}