import Typography from "@mui/material/Typography";
import React from "react";
import {Box, Button, Divider, List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import {useNavigate} from "react-router-dom";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

type userInstitutionPageProps = {
    portfolios?: Portfolio[];
}

type Portfolio = {
    portfolioKey: number
    portfolioName: string
}

export function InstitutionPortfoliosList({portfolios = []}: userInstitutionPageProps) {

    return (
        <Paper elevation={4} style={{height: 400, width: '100%'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2, paddingTop: 1.5 }}>
                <Typography variant="h6" component='div' >
                    Portfolios
                </Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ height: 'calc(100% - 56px)', overflowY: 'auto' }}>
                <List>
                    {
                        portfolios.map( (portfolio : Portfolio) => (
                            <PortfolioDisplay portfolio={portfolio}/>
                        ))
                    }
                </List>
            </Box>
        </Paper>
    );
}

type PortfolioDisplayProps = {
    portfolio: Portfolio
}

function PortfolioDisplay({portfolio}: PortfolioDisplayProps) {

    const goTo = useNavigate()

    return(
        <>
            <Box>
                <ListItem>
                    <ListItemIcon>
                        <PaidOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText primary={portfolio.portfolioName}/>
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/portfolios/${portfolio.portfolioKey}`)}
                    >
                    # {portfolio.portfolioKey}
                    </Button>
            </ListItem>
            <Divider sx={{my: 1}}/>
            </Box>
        </>
);
}
