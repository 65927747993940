import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { DashboardLayout } from './layouts/dashboard/DashboardLayout';
import { PageNotFound } from './pages/splash/PageNotFound';
import { ListUsersPage } from './pages/users/ListUsersPage';
import { LoginPage } from './pages/login/LoginPage';
import { LogoutPage } from './pages/login/LogoutPage';
import { ForbiddenPage } from './pages/splash/ForbiddenPage';
import { UserPilotDetailsPage } from './pages/users/UserPilotDetailsPage';
import  UpdateAssetFormPage   from './pages/assets/UpdateAssetPage'
import PortfolioEventPage from './pages/users/PortfolioEventPage'
import Home from './pages/home/Home'
import {ViewUserPage} from "./pages/users/ViewUserPage";
import PortfolioEventsGeneric from "./pages/portfolioEvents/PortfolioEventsGeneric";
import {UserInstitutionPage} from "./pages/users/UserInstitutionPage";
import TraceVisualizer from "./pages/traceVisualizer/TraceVisualizer";
import UserPortfolioReDesign from "./pages/users/UserPortfolioReDesign";
import {ViewPilotsPage} from "./pages/pilots/ViewPilotsPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path='/forbidden' element={<ForbiddenPage />} />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route element={<DashboardLayout />}>
        <Route path='/' element={<Home />} />
        <Route path='/users' element={<ListUsersPage />} />
        <Route path='/users/:userKey' element={<ViewUserPage />} />
        <Route path='/assets/:symbol' element={<UpdateAssetFormPage />} />
        <Route path='/pilot-details/:autoPilotSettingsKey' element={<UserPilotDetailsPage />} />
        <Route path='/portfolios/:portfolioKey' element={<UserPortfolioReDesign />} />
        <Route path='/*' element={<PageNotFound />} />
        <Route path='/portfolio-event/:eventKey' element={<PortfolioEventPage />} />
        <Route path='/institution/:institutionKey' element={<UserInstitutionPage />} />
        <Route path='/portfolio-events' element={<PortfolioEventsGeneric />} />
        <Route path='/trace/:traceID' element={<TraceVisualizer />} />
        <Route path='/pilots' element={<ViewPilotsPage/>} />
      </Route>
    </Route>
  )
);

export function AppRouter() {
  return <RouterProvider router={router} />;
}
