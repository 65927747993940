import React, { useState } from 'react';
import '../css/slave-portfolio-header-style.css';
import '../css/slave-portfolio-card-style.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from "@mui/material/Box";

const SlavePortfolioHeader = (
    {
        data,
        loading,
        error,
        currentOption,
        onChangeOption,
        dataSlave
    }
) => {

    const optionsList = [
        "all",
        "day",
        "week",
        "month",
        "ytd"
    ]

    const currentData = data ? data[currentOption] : null;
    const [showDropdown, setShowDropdown] = useState(false);


    const toggleDropdown = () => setShowDropdown(!showDropdown);

    const handleOptionClick = (option) => {
        onChangeOption(option);
        setShowDropdown(false);
    };

    return (
        <div className="header">
            <div className='card-column-items alignItemsStart'>
                <h1 style={{fontSize: '25px', marginTop: '30px'}}>{`$${(dataSlave?.totalValue || 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).slice(1)}`}
                </h1>
                <p
                    style={{
                        color: currentData.totalProfitLoss < 0 ? 'red' : 'green',
                    }}
                >{`${(currentData?.totalProfitLoss || 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} ${currentData.totalProfitLoss < 0 ? '' : '+'}${(currentData.totalProfitLossPercent * 100).toLocaleString('en-US', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                })}%`}</p>
            </div>

            <div className="dropdown">
                <Box display="flex" marginBottom={2}>
                    <button onClick={toggleDropdown} style={{fontSize: '17px', color: 'black'}}>{currentOption}</button>
                    <ArrowDropDownIcon />
                </Box>
                {showDropdown && (
                    <div className="dropdown-content">
                        {optionsList.map((option) =>
                            <p onClick={() => handleOptionClick(option)}>{option}</p>
                        )
                        }
                    </div>

                )}
            </div>
        </div>
    );
};

export default SlavePortfolioHeader;
