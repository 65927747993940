import Box from "@mui/material/Box";
import {Button, Card, Divider, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import React from "react";
import {useNavigate} from "react-router-dom";


interface props{
    data: pilotDetails
}

interface pilotDetails{
    masterName: string,
    masterPortfolioKey: string
}

export default function PortfolioEventPilotDetails ({data} : props) {

    const goTo = useNavigate();

    return (
        <Card elevation={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2, paddingTop: 2 }}>
                <br />
                <Typography variant="h6" component='div' >
                    Pilot Details
                </Typography>
                <Box sx={{ flexGrow: 2 }} />
            </Box>
            <Divider sx={{ my: 1 }} />
            <ListItem sx={{marginBottom: 1}}>
                <ListItemIcon>
                    <AirplanemodeActiveIcon />
                </ListItemIcon>
                <ListItemText primary={`Pilot: ${data.masterName}`} />
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    color='primary'
                    endIcon={<ArrowForwardOutlinedIcon />}
                    onClick={() => goTo(`/portfolios/${data.masterPortfolioKey}`)}
                >
                    #{data.masterPortfolioKey}
                </Button>
            </ListItem>
        </Card>
    )
}