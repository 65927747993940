import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {gql} from "@apollo/client";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {
    Alert,
    CircularProgress,
    Grid,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import PortfolioEventCrudPanel from "../../views/portfolios/PortfolioEventCrudPanel";
import PortfolioEventPilotDetails from "../../views/portfolios/PortfolioEventPilotDetails";
import PortfolioEventBrokerACHDetails from "../../views/portfolios/PortfolioEventBrokerACHDetails";


export default function PortfolioEventPage () {

    const {eventKey} = useParams() || '';
    const goTo = useNavigate();

    const PortfolioEventDetailsGet = gql`
    query ( $portfolioEventKey: Int! ){
        portfolioEventDetailsGet(input: {
            portfolioEventKey: $portfolioEventKey
        }){
            portfolioEvent{
                userKey
                userFullName
                status
                type
                brokerName
                portfolioKey
                amount
                createdAt
                lastPolledAt
                reason
                autoPilotSettingsKey
            }
            pilotDetails{
                masterName
                masterPortfolioKey
            }
            brokerACHDetails{
              amount
              amountOnHold
              status
              earlyAccessAmount
              submittedAt
              settledAt
              createdAt
            }
        }
    }`;

    const {loading, error, data} = useQueryCoordinate(PortfolioEventDetailsGet, {variables: { portfolioEventKey : parseInt(eventKey as string)}});

    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'PortfolioEventDetailsGet'</Alert>;
    }

    return (
        <Box>
            <Typography variant="h4">Portfolio Event Key: {eventKey}</Typography>
            <br />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <PortfolioEventCrudPanel eventKey={eventKey as string} data={data?.portfolioEventDetailsGet?.portfolioEvent} />
                </Grid>
                <Grid item xs={4}>
                    <PortfolioEventPilotDetails data={data?.portfolioEventDetailsGet?.pilotDetails} />
                    <PortfolioEventBrokerACHDetails data={data?.portfolioEventDetailsGet?.brokerACHDetails} />
                </Grid>
            </Grid>

        </Box>
    )
}