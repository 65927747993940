import {gql} from "@apollo/client";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {Button, Tooltip} from "@mui/material";
import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import * as _ from "lodash";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import moment from "moment/moment";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import {useNavigate} from "react-router-dom";

interface PortfolioEvent {
    portfolioKey: string;
    portfolioEventKey: string;
    status: string;
    type: string;
    amount: number;
    createdAt: string;
    reason: string;
    autoPilotSettingsKey: string;
    brokerName: string;
    userKey: string;
}

export default function PortfolioEventsGeneric(){

    const portfolioEventsGet = gql`
    query portfolioEventsGet (
        $cursor: Int!){
            portfolioEventsGet( input: {cursor: $cursor} ) {
                portfolioEvents {
                    portfolioKey
                    portfolioEventKey
                    status
                    type
                    portfolioKey
                    amount
                    createdAt
                    reason
                    autoPilotSettingsKey
                    brokerName
                    userKey
                }
                nextCursor
            }
    }`;

    const goTo = useNavigate()

    const [cursor, setCursor] = useState(0);
    const [portfolioEvents, setPortfolioEvents] = useState<PortfolioEvent[]>([]);

    const { data, loading, error } = useQueryCoordinate(portfolioEventsGet, {
        variables: { cursor },
    });

    useEffect(() => {
        if (data) {
            console.log(data);
            setPortfolioEvents(prevEvents => [
                ...prevEvents,
                ...data.portfolioEventsGet.portfolioEvents,
            ]);
            setCursor(parseInt(data.portfolioEventsGet.nextCursor));
        }
    }, [data, loading, cursor]);

    var isLoading: boolean = true;

    if(error && !loading){
        isLoading = false;
    }

    const columns: GridColDef[] = [
        {   field: 'id',
            headerName: 'Event Key',
            width: 120,
            renderCell: (params : GridRenderCellParams) => {
                return (
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/portfolio-event/${params.value}`)}
                    >
                        #{params.value}
                    </Button>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            renderCell: (params : GridRenderCellParams) => {
                return (
                <Tooltip title={['COMPLETED', 'IN_PROGRESS', 'INITIATED'].includes(params.value) && !params.row.reason ? _.capitalize(params.value) : params.row.reason}>
                    <div>
                        {params.value === 'COMPLETED' && <TaskAltOutlinedIcon style={{color: '#4caf50', verticalAlign: 'middle'}} />}
                        {params.value === 'IN_PROGRESS' && <MoreHorizOutlinedIcon style={{verticalAlign: 'middle', color: 'orange'}}/>}
                        {params.value === 'INITIATED' && <MoreHorizOutlinedIcon style={{verticalAlign: 'middle', color: 'orange'}}/>}
                        {params.value === 'CANCELED' && <ErrorIcon color='error' style={{verticalAlign: 'middle'}} />}
                        {params.value === 'FAILED' && <ErrorIcon color='error' style={{verticalAlign: 'middle'}} />}
                    </div>
                </Tooltip>
                )
            }
        },
        {
            field: 'userKey',
            headerName: 'User',
            width: 140,
            renderCell: (params : GridRenderCellParams) => {
                return (
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/users/${params.value}`)}
                    >
                        #{params.value}
                    </Button>
                )
            }
        },
        {
            field: 'portfolioKey',
            headerName: 'Portfolio',
            width: 140,
            renderCell: (params : GridRenderCellParams) => {
                return (
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/portfolios/${params.value}`)}
                    >
                        #{params.value}
                    </Button>
                )
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 120,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 120,
            renderCell: (params: GridRenderCellParams ) => {
                return params.value === null ? 'null' : parseInt(params.value)?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 160,
            renderCell: (params: GridRenderCellParams ) => {
                return moment(params.value).format('MM/DD/YYYY HH:mm')
            }
        },
        {
            field: 'reason',
            headerName: 'Reason',
            width: 70,
            renderCell: (params : GridRenderCellParams) => {
                return (
                    <Tooltip title={params.value}>
                        <div>
                            {params.value === null ? 'N/A' : <InfoIcon style={{ verticalAlign: 'middle' }} />}
                        </div>
                    </Tooltip>
                )
            }
        },
        {
            field: 'settingsKey',
            headerName: 'Pilot Details',
            width: 150,
            renderCell: (params : GridRenderCellParams) => {
                return (
                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        color='primary'
                        endIcon={<ArrowForwardOutlinedIcon />}
                        onClick={() => goTo(`/pilot-details/${params.value}`)}
                    >
                        #{params.value}
                    </Button>
                )
            }
        },
        {
            field: 'brokerName',
            headerName: 'Broker',
            width: 140,
        }
    ];

    const rows = portfolioEvents.map(row => {
        return {
            id: row.portfolioEventKey,
            status: row.status,
            userKey: row.userKey,
            portfolioKey: row.portfolioKey,
            type: row.type,
            amount: row.amount ,
            createdAt: row.createdAt,
            reason: row.reason,
            settingsKey: row.autoPilotSettingsKey,
            brokerName: row.brokerName
        };
    });

    return(
        <div>
            <Box sx={{ height: '60%', width: '80%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 25,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'desc' }],
                        },
                    }}
                    loading={isLoading}
                    pageSizeOptions={[25]}
                    slots={{ toolbar: GridToolbar }}
                />
            </Box>
        </div>
    )
}