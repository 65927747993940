import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { TokenContainer } from './base/TokenContainer';
import { ClientBase } from './base/ClientBase';
import { AuthClient } from './http/AuthClient';
import { EntityClient } from './http/EntityClient';
import Cookies from 'js-cookie';

export const GQL_URI = process.env.REACT_APP_GQL_API;
export const HTTP_URI = process.env.REACT_APP_HTTP_API;
export const LEGACY_ADMIN_URL = process.env.REACT_APP_LEGACY_ADMIN_URL;

export const IS_PROD = process.env.REACT_APP_STAGE?.toLowerCase() === 'prod';

const httpLink = new HttpLink({
  uri: GQL_URI
});
const authLink = setContext((_, { headers }) => {
  // Get the authentication token from cookies
  const token = Cookies.get('irisAdmin');

  // Return the headers to the context so any link can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.includes("Not Authorized") || message.includes("Forbidden")) {
        // Redirect to /forbidden
        window.location.href = '/forbidden';
      }
    });
  }
});
const link = authLink.concat(errorLink).concat(httpLink); // This ensures the authLink forwards the request to the httpLink
export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

export const tokenContainer = new TokenContainer();
const clientBase = new ClientBase(HTTP_URI, tokenContainer);
export const authClient = new AuthClient(clientBase);
export const entityClient = new EntityClient(clientBase);