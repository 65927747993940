import React from 'react';
import '../css/slave-portfolio-card-style.css';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";

const SlavePortfolioCard = ({ title, subtitle, amount, change,isPendingTransfer, imageSrc, virtualText, isLosingMoney, onClick }) => {

    const themes = useTheme();

    return <div className="card" style={{ backgroundImage: `url(${imageSrc})` }} onClick={onClick}>
        <Box sx={{
            flexDirection: 'column',
            display: 'flex',
            margin: '0.5rem',
        }}>
            {/*<div className="card-header">*/}
                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '100%',
                }}>
                    {/*Card Amount*/}
                    <Typography fontWeight={"bold"}  fontSize={'1.1vw'} style={{ color: isPendingTransfer ? 'gray' : 'white' }}>
                        ${(amount ?? 0.0).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).slice(1)}</Typography>
                    {/*Pilot*/}
                    <Typography fontSize={'1vw'}>{title}</Typography>
                </Box>

                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                    {/*Percent Change*/}
                    <Typography fontSize={'0.9vw'} style={{ color: isLosingMoney ? 'red' : themes.palette.success.main}}>{isPendingTransfer?'Deposit Pending':change}</Typography>
                    <p className="card-subtitle">{subtitle}</p>
                </Box>

            </Box>
            <Box sx={{
                textAlign: 'start',
                userSelect: 'none',
                paddingBottom: '0.5rem',
                paddingLeft: '0.5rem',
            }}>
                <Typography sx={{
                    fontSize: '0.8em',
                    userSelect: 'none',
                }}>{virtualText}</Typography>
            {/*</div>*/}
        </Box>
    </div>;
};

export default SlavePortfolioCard;
