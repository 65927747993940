import {gql} from "@apollo/client";
import {useQueryCoordinate} from "../../clients/graphql/graphql.hooks";
import {
    Alert,
    CardContent,
    CircularProgress, Dialog,
    Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ToolTip from "@mui/material/Tooltip"
import {PieChart} from "@mui/x-charts/PieChart";
import {DataGrid} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import InfoIcon from '@mui/icons-material/Info';
import {useState} from "react";


export function InstitutionHealthTile () {
    const jediStatusBreakdownQuery = gql`
    query{
      jediStatusBreakdownQuery{
        data{
          brokerName,
          inEquilibrium,
          pendingNextCycle,
          suppressed,
          unknownState,
          shortCircuited,
          total,
          percentInEquilibrium,
          percentPendingNextCycle,
          percentSuppressed,
          percentShortCircuited,
          percentUnknownState,
          topReasons
        }
      }
    }`;

    const {loading, error, data} = useQueryCoordinate(jediStatusBreakdownQuery, {});

    let tableData = data?.jediStatusBreakdownQuery?.data
    const [selectedType, setSelectedType] = useState(-1);

    let totalEquilibrium = 0;
    let totalPending = 0;
    let totalSuppressed = 0;
    let totalUnknownState = 0;

    if (loading) {
        return <CircularProgress size={60} sx={{ display: 'flex', justifySelf: "center", alignSelf: "center", margin: "auto"}} />;
    }

    tableData.forEach(row => {
        totalEquilibrium += row.inEquilibrium;
        totalPending += row.pendingNextCycle;
        totalSuppressed += row.suppressed;
        totalUnknownState += row.unknownState;
    })

    if (error) {
        return <Alert severity="warning">INTERNAL ERROR: error querying 'jediStatusBreakdownQuery'</Alert>;
    }

    function joinArrayWithCommas(arr) {
        return arr.join(', ');
    }

    const columns = [
        { field: 'id', headerName: 'Broker Name', width: 120 },
        {
            field: 'inEquilibrium',
            headerName: 'In Equilibrium',
            width: 103,
        },
        {
            field: 'pendingNextCycle',
            headerName: 'Pending Next Cycle',
            width: 120,
        },
        {
            field: 'suppressed',
            headerName: 'Suppressed',
            width: 98,
        },
        {
            field: 'unknownState',
            headerName: 'Unknown State',
            width: 120,
        },
        {
            field: 'shortCircuited',
            headerName: 'Short Circuited',
            width: 120,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 70,
        },
        {
            field: 'topReasons',
            headerName: 'Top Reasons',
            renderCell: (params) => {
                return(
                        <ToolTip title={joinArrayWithCommas(params.row.topReasons)}>
                            {params.row.topReasons.length === 1 ? 'none' : <InfoIcon style={{verticalAlign: "middle"}} />}
                        </ToolTip>
                )
            },
            width: 100,
        },
    ];

    const rows = tableData.map(row => {
        return {
            id: row.brokerName,
            inEquilibrium: row.inEquilibrium + ' (' + (row.percentInEquilibrium * 100).toPrecision(2) + '%)',
            pendingNextCycle: row.pendingNextCycle + ' (' + (row.percentPendingNextCycle * 100).toPrecision(2) + '%)',
            shortCircuited: row.shortCircuited + ' (' + (row.percentShortCircuited * 100).toPrecision(2) + '%)',
            suppressed: row.suppressed + ' (' + (row.percentSuppressed * 100).toPrecision(2) + '%)',
            total: row.total,
            unknownState: row.unknownState + ' (' + (row.percentUnknownState * 100).toPrecision(2) + '%)',
            topReasons: row.topReasons
        };
    });

    const institiutionHealthData = [
        {
            id: 0,
            value: totalEquilibrium,
            label: 'Total Equilibrium',
            color: '#99cb76'
        },
        {   id: 1,
            value: totalPending,
            label: 'Total Pending',
            color: "#78b2e6"},
        {
            id: 2,
            value: totalSuppressed,
            label: 'Total Suppressed',
            color: "#fafa7d"
        },
        {
            id: 3,
            value: totalUnknownState,
            label: 'Total Unknown State',
            color: "#b0b0b0"
        },
    ]

    function getColumn(type) {
        const newCols = [
            { field: 'id', headerName: 'Broker Name', width: 130 },
            {
                field: 'data',
                headerName: institiutionHealthData[type].label,
                width: 140
            }
        ]
        return newCols;
    }

    function getRows(type){
        let data = null
        let specRows = tableData.map(row => {
            switch(type){
                case 0:
                    data = row.inEquilibrium;
                    break;
                case 1:
                    data = row.pendingNextCycle;
                    break;
                case 2:
                    data = row.suppressed;
                    break;
                case 3:
                    data = row.unknownState;
                    break;
                default:
                    data = 'null';
            }
            return {
                id: row.brokerName,
                data: data,
            };
        })
        return specRows;
    }


    return (
        <>
            <CardContent>
                <Typography sx={{paddingLeft: "10px", fontWeight: "bold"}} component="h1" variant="h5">
                    Institution Health
                </Typography>
                <div className="papers" style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginTop: "10px"
                }}>
                    <Paper elevation={4} sx={{flex: "8"}}>
                        <Box sx={{ height: 475, width: '100%' }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 8,
                                        },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'total', sort: 'desc' }],
                                    },
                                }}
                                pageSizeOptions={[8]}
                            />
                        </Box>
                    </Paper>
                    <div style={{flex: "1"}}></div>
                    <Paper elevation={4} sx={{width: "30%", height: "30%"}}>
                        <PieChart
                            series={[
                                {
                                    data: institiutionHealthData,
                                    cx: "20%",
                                    highlightScope: {faded: 'global', highlighted: 'item'},
                                    faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                                    id: 'testing'
                                },
                            ]}
                            onItemClick={(event, d) => setSelectedType(d.dataIndex)}
                            height={200}
                            width={400}
                            sx={{paddingTop: "9%", paddingBottom: "4%"}}
                        />
                    </Paper>

                    {selectedType !== -1 && (
                        <Dialog
                            open={selectedType !== -1}
                            onClose={() => setSelectedType(-1)}
                            PaperProps={{
                                style: {
                                    maxHeight: '80vh',
                                    overflow: 'auto',
                                },
                            }}
                        >
                            <DataGrid
                                columns={getColumn(selectedType)}
                                rows={getRows(selectedType)}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 8,
                                        }
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'data', sort: 'desc' }],
                                    },
                                }}
                                pageSizeOptions={[8]}
                                sx={{width: "100%"}}
                            />

                        </Dialog>
                    )}
                </div>
            </CardContent>
        <br/>
        </>)
}