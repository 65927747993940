import { gql } from '@apollo/client';

export const jediUpdateAssetMutation = gql`
    mutation jediUpdateAsset(
        $symbol: String!
        $description: String
        $pictureData: String
        $pictureName: String
        $name: String!
    ) {
        jediUpdateAsset(input: {
            symbol: $symbol
            description: $description
            pictureData: $pictureData
            pictureName: $pictureName
            name: $name
        }) {
            asset {
                assetKey
                name
                description
                companyUrl
                pictureUrl
                symbol
            }
            success
            message
        }
    }
`;
export const jediAssetQueryBySymbol = gql`
query jediAssetQuery($symbol: String) {
    assetSearch(input: { symbol: $symbol }) {
      assets {
        assetKey
        name
        description
        companyUrl
        pictureUrl
        symbol
      }
    }
  }
`;

