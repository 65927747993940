import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock'; // Importing the Lock icon
import Box from '@mui/material/Box';

export function ForbiddenPage() {
  return (
    <Container maxWidth="sm">
      <Box textAlign="center" mt={5}>
        <LockIcon style={{ fontSize: 80, color: 'red' }} />
        <Typography variant="h4" gutterBottom>
          403 Forbidden
        </Typography>
        <Typography variant="subtitle1">
          You do not have permission to access this page.
        </Typography>
      </Box>
    </Container>
  );
}