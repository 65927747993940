import {Accordion, AccordionDetails, AccordionSummary, Paper, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment/moment";

interface ExecutionItem {
    executionId: string;
    prevExecutionId: string;
    queueName: string;
    startedAt: string;
    endAt: string;
    triggeredAt: string;
    children?: ExecutionItem[];
}


interface ExecutionAccordionProps {
    data: ExecutionItem[];
}


const groupData = (data: ExecutionItem[]): ExecutionItem[] => {
    const lookup: { [key: string]: ExecutionItem } = {};
    const result: ExecutionItem[] = [];

    data.forEach(item => {
        lookup[item.executionId] = { ...item, children: [] };
    });

    data.forEach(item => {
        if (item.prevExecutionId && lookup[item.prevExecutionId]) {
            lookup[item.prevExecutionId].children!.push(lookup[item.executionId]);
        } else {
            result.push(lookup[item.executionId]);
        }
    });

    return result;
};

const ExecutionAccordion = ({ data } : ExecutionAccordionProps) => {
    const groupedData = groupData(data);

    const renderAccordion = (items: ExecutionItem[]) => {
        return items.map((item, index) => (
            <Accordion key={index} sx={{marginTop: '10px'}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>{item.queueName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        {/*<strong>Execution ID:</strong> {item.executionId}<br />*/}
                        {/*<strong>Previous Execution ID:</strong> {item.prevExecutionId}<br />*/}
                        <strong>Queue Name:</strong> {item.queueName}<br />
                        <strong>Started At:</strong> {moment(item.startedAt).format('MM/DD/YYYY HH:mm:ss')}<br />
                        <strong>End At:</strong> {moment(item.endAt).format('MM/DD/YYYY HH:mm:ss')}<br />
                        {/*<strong>Triggered At:</strong> {moment(item.triggeredAt).format('MM/DD/YYYY HH:mm:ss')}*/}
                    </Typography>
                    {item.children && item.children.length > 0 && (
                        <div style={{ paddingLeft: '20px' }}>
                            {renderAccordion(item.children)}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        ));
    };

    return (
        <Paper elevation={4}>
            {renderAccordion(groupedData)}
        </Paper>
        )
};

export default ExecutionAccordion;