import { gql } from '@apollo/client';

export const jediReenableSlave = gql`
    mutation jediReenableSlave(
        $autoPilotSettingsKey: Int!
    ) {
        jediReenableSlave(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            success
        }
    }
`;

export const jediResolveOverAllocation = gql`
    mutation jediResolveOverAllocation(
        $autoPilotSettingsKey: Int!
    ) {
        jediResolveOverAllocation(input: {
            autoPilotSettingsKey: $autoPilotSettingsKey
        }) {
            success
        }
    }
`;