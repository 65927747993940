export enum CONNECTION_STATUS {
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    PENDING = 'PENDING'
}

export enum BROKER_NAME {
    ROBINHOOD = 'ROBINHOOD',
    FIDELITY = 'FIDELITY',
    SCHWAB = 'SCHWAB',
    WEBULL = 'WEBULL',
    TD_AMERITRADE = 'TD_AMERITRADE',
    E_TRADE = 'E_TRADE',
}