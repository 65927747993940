import { useState, useEffect } from 'react';
import { CssBaseline, Box, Toolbar } from '@mui/material';
import { SideBar } from './SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import { HeaderBar } from './HeaderBar.tsx';
import { IconLoader } from '../../components/loaders/IconLoader';
import { apolloClient, tokenContainer } from '../../clients/ClientRegistry';
import { GQL_WhoamiBasic } from '../../clients/graphql/user/user-info.query';

const drawerWidth = 240;

const fetchWhoami = async () => {
  const { data, error } = await apolloClient.query({
    query: GQL_WhoamiBasic,
    fetchPolicy: 'network-only',
    context: {
      headers: {
        coordinate: process.env.REACT_APP_COORDINATE
      }
    }
  });
  return { data, error };
}

export function DashboardLayout() {

  let goTo = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function validateToken() {
      if (!tokenContainer.hasToken()) {
        goTo('/login');
      } else {
        const { data, error } = await fetchWhoami();
        if (error) {
          goTo('/forbidden');
        }
        setLoading(false);
      }
    }
    validateToken();
  }, [goTo]);

  if (loading) {
    return <IconLoader text="Validating credentials...." fullscreen />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <SideBar width={drawerWidth} /> */}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <HeaderBar />
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
