import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Box, Grid, styled, Typography,} from '@mui/material';
import {UserWarningList} from '../../views/user/UserWarningsList';
import {LegacyAdminButton} from '../../components/buttons/LegacyAdminButton';
import {HomeScreen} from '../../views/mobile/home/home-screen'
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {GQL_WhoamiWithSubscriptions} from "../../clients/graphql/user/user-info.query";
import {UserCrudPanel} from "../../views/user/UserCrudPanel";
import DeleteUserButton from "../../components/buttons/DeleteUserButton";
import {DownloadTradesButton} from "../../components/buttons/DownloadTradesButton";
import {ZendeskUserButton} from "../../components/buttons/ZendeskUserButton";
import {PilotListTable} from "../../components/tables/PilotListTable";
import {TriggerHistoryTable} from "../../components/tables/TriggerHistoryTable";

const Item = styled(Box)(({ theme }) => ({
    border: '1px solid rgba(18, 18, 18, 0.15)',
    borderRadius: '5px',
    marginTop: 10,
    marginBottom: 10,
    overflow: 'hidden',
}));


export function SlaveDetailHoldings(){
    const { userKey } = useParams();
    const [slavePortfolio, setSlavePortfolio] = useState(null);
    const [slavePerformanceData, setSlavePerformanceData] = useState(null);
    const [currentOption, setCurrentOption] = useState("all");
    const current = slavePerformanceData ? slavePerformanceData[currentOption]?.slavePerformance : null;

    const slaveDataByAutopilotSetting = current?.find(
        (f) => f?.autoPilotSettingsKey === slavePortfolio?.autoPilotSettingsKey
    )

    useEffect(() => {
        setSlavePortfolio(null);
        setSlavePerformanceData(null);
    }, [userKey]);

    const handleDialogCloseInViewUser = () => {
        setSlavePortfolio(null); // Reset portfolio
        setSlavePerformanceData(null); // Reset performance data
    };

    return(
            <HomeScreen
                userKey={userKey}
                setParams={(slave, performance) => {
                    setSlavePortfolio(slave);
                    setSlavePerformanceData(performance);
                }}
                onChangeOptionHome={(f) => {
                    setCurrentOption(f);
                }}
                currentOptionHome={currentOption}
                slavePortfolio={slavePortfolio}
                slaveDataByAutopilotSetting={slaveDataByAutopilotSetting}
                onDialogClose={handleDialogCloseInViewUser}
            />
    );
}


export function ViewUserPage() {

    const { userKey } = useParams();
    const {  data } = useQueryAsUser(GQL_WhoamiWithSubscriptions, { userKey, variables: { userKey } })
    const firstName = data?.whoami.firstName;
    const lastName = data?.whoami.lastName;
    const userID = data?.whoami.zendeskUserId;

    return <Box>
        <Typography variant="h4" paddingBottom={2}>
            {firstName} {lastName}
        </Typography>
        <Box display={"flex"} gap={1}>
            <LegacyAdminButton path={`/users/${userKey}`}/>
            <ZendeskUserButton userId={userID} />
            <DownloadTradesButton userKey={userKey}/>
            <DeleteUserButton userKey={userKey}/>
        </Box>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <UserWarningList userKey={userKey} />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={2.5}>
                <Item sx={{height: 1}}>
                    <UserCrudPanel userKey={userKey} />
                </Item>
            </Grid>
            <Grid item xs={7.5}>
                <Item sx={{height: 1, paddingX: '1rem'}}>
                    <PilotListTable userKey={userKey}/>
                    <TriggerHistoryTable info={{ userKey: parseInt(userKey), isUserView: true, autoPilotSettingsKey: undefined }} />
                </Item>
            </Grid>
            <Grid item xs={2}>
                <Item sx={{height: 1}}>
                    <SlaveDetailHoldings/>
                </Item>
            </Grid>
        </Grid>
    </Box>
}