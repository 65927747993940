import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface ConfirmAlertDialogProps {
    buttonComponent?: React.ReactNode;
    onConfirm: () => void;
    actionButtonTitle?: string;
    title: string;
    description: string;
}

export default function ConfirmAlertDialog(props: ConfirmAlertDialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    props.onConfirm();
    setOpen(false);
  }

  return (
    <React.Fragment>
        {
            props.buttonComponent 
            ? <span onClick={handleClickOpen}>{props.buttonComponent}</span>
            :
            <Button variant="outlined" onClick={handleClickOpen}>
                {props.actionButtonTitle}
            </Button>
        }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Nevermind</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}