import React, {useEffect, useState} from 'react';
import '../home/css/home-screen-style.css';
import {Dialog, Typography} from '@mui/material';
import {UserHoldingsTable} from '../../../components/tables/UserHoldingsTable';

export const SlaveDetailScreen = ({ slavePortfolio, slaveDataByAutopilotSetting, onDialogClose }) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    // Open the dialog whenever `slavePortfolio` changes and is truthy
    useEffect(() => {
        if (slavePortfolio) {
            setDialogOpen(true);
        }
    }, [slavePortfolio]);

    const handleDialogClose = () => {
        setDialogOpen(false); // Closes the dialog when called
        onDialogClose();
    };

    if (!slavePortfolio || !slaveDataByAutopilotSetting) {
        return (
            <Typography variant="p" component="div" fontSize={'small'}>
                Select a card to view details
            </Typography>
        );
    }
    return (
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <UserHoldingsTable
                    holdings={slavePortfolio.currentPositions.concat((slavePortfolio.autoPilotCashHolding?.marketValue !== 0.0 ? [{
                        symbol: slavePortfolio.autoPilotCashHolding?.name,
                        name: slavePortfolio.autoPilotCashHolding?.name,
                        pictureUrl: "https://storage.googleapis.com/iris-main-prod/assets/symbol-pictures/cashapplogo.png",
                        marketValue: slavePortfolio.autoPilotCashHolding?.marketValue,
                        quantity: 0.0,
                        percentOfPortfolio: slavePortfolio.autoPilotCashHolding?.percentOfPortfolio,
                        currentPrice: 0.0,
                        unrealizedProfitLoss: 0.0,
                        unrealizedProfitLossPercent: 0.0,
                        pilot: slavePortfolio.masterPortfolio.userFullName,
                    }] : []))}/>
            </Dialog>
    );
};
