import { gql } from '@apollo/client';

export const jediFailedAutopilotOrdersGet = gql`
    query jediFailedAutopilotOrdersGet(
        $userKey: Int
        $portfolioKey: Int
        $autoPilotSettingsKey: Int
        $limit: Int!
        $offset: Int!
    ) {
        jediFailedAutopilotOrdersGet(input: {
            userKey: $userKey
            portfolioKey: $portfolioKey
            autoPilotSettingsKey: $autoPilotSettingsKey
            limit: $limit
            offset: $offset
        }) {
            autoPilotOrders {
                asset {
                    pictureUrl
                }
                symbol
                slavePortfolioKey
                orderSide
                quantity
                averagePrice
                createdAt
                failureReason
            }
        }
    }
`;

export interface JediFailedAutopilotOrdersResult {
    jediFailedAutopilotOrdersGet: {
        autoPilotOrders: JediFailedAutopilotOrder[];
    };
}

export interface JediFailedAutopilotOrder {
    asset: {
        pictureUrl: string;
    };
    symbol: string;
    slavePortfolioKey: string;
    orderSide: string;
    quantity: number;
    averagePrice: number;
    createdAt: string;
    failureReason: string;
}