import React, {useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box, Card,
    CircularProgress,
    List,
    ListItem,
    Typography
} from "@mui/material";
import {useQueryAsUser} from "../../clients/graphql/graphql.hooks";
import {
    InstitutionData,
    InstitutionPortfolio,
    listInstitutionsQuery
} from "../../clients/graphql/user/user-institutions.query";
import {Link} from "react-router-dom";
import _ from 'lodash';
import {ConnectedStatus} from "../../components/status/ConnectedStatus";
import {DisconnectedStatus} from "../../components/status/DisconnectedStatus";

export type UserInstitutionsListProps = {
    userKey: number,
}
export function UserInstitutionsList({ userKey }: UserInstitutionsListProps) {

    const {loading, error, data} = useQueryAsUser(listInstitutionsQuery, { userKey, variables: { userKey } });

    const unsortedInstitutionList: InstitutionData[] = data?.institutionsGet?.institutions ?? [];
    const institutionList = _.sortBy(unsortedInstitutionList, ['connectionStatus']);
    return (
            <Box sx={{
                justifyContent: 'space-between',
                textAlign: 'center',
                height: '35.3rem',
                overflowY: 'scroll',
                borderRadius: '8px',
                border: '1px solid lightgray',
                marginLeft: '.75rem',
                marginRight: '.75rem',
            }}
            >
                <List>
                    {
                        loading ? <CircularProgress/> :
                            error ? <Alert severity="warning">{error?.message}</Alert> :
                                institutionList.map((institution: InstitutionData, index: number) => <InstitutionDisplay key={index} userKey={userKey} institution={institution} />)
                    }
                </List>
            </Box>
    );
}

type InstitutionDisplayProps = {
    userKey: number,
    institution: InstitutionData,
}
function InstitutionDisplay({ userKey, institution }: InstitutionDisplayProps) {

    const [expanded, setExpanded] = useState<boolean>(false);

    return <Accordion expanded={expanded} onClick={() => setExpanded(!expanded)} >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
            >
                <Typography fontSize={'small'}>{institution.institutionName.split('_').map(_.capitalize).join(' ')}</Typography>
                {institution.connectionStatus === 'CONNECTED' ? <ConnectedStatus/> :
                    <DisconnectedStatus/>
                }
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <InstitutionDetails userKey={userKey} institution={institution}/>
        </AccordionDetails>
    </Accordion>
}

type InstitutionDetailsProps = {
    userKey: number,
    institution: InstitutionData,
}
function InstitutionDetails({ institution }: InstitutionDetailsProps) {

    const portfolios = (institution?.portfolios ?? [])?.map((portfolio: InstitutionPortfolio) => {
        return (
        <ListItem component={Link} to={`/portfolios/${portfolio.portfolioKey}`} disablePadding>
            <Card sx={{
                color: 'black',
                backgroundColor: 'white',
                size: 'small',
                borderRadius: '8px',
                padding: '0.3rem',
                marginBottom: '0.5rem',
                border: '1px solid grey',
                width: '100%',
                boxShadow: 'none'
            }}>
                <Typography sx={{
                    fontSize: 'small',
                    color: 'black',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    {portfolio?.nickname ?? 'N/A'}
                    <span style={{marginLeft: 'auto'}}>
                        {(portfolio?.accountId?.length > 8 ? '****' : '*'.repeat(Math.max(portfolio?.accountId?.length - 4, 0))) + portfolio?.accountId?.slice(-4)}
                    </span>
                </Typography>
            </Card>
        </ListItem>
        )
    });

    return <Box sx={{ textAlign: 'left', flexDirection: 'row', marginTop: '-0.5rem' }}>
        <Typography fontWeight={'bold'}>Portfolios:</Typography>
        <List>
            {portfolios}
        </List>
    </Box>
}


