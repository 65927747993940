import { useNavigate } from 'react-router-dom';
import { tokenContainer } from '../../clients/ClientRegistry';
import { IconLoader } from '../../components/loaders/IconLoader';
import { useEffect } from 'react';

export function LogoutPage() {

  const goTo = useNavigate();
  useEffect(() => {
    tokenContainer.clearToken();
    goTo('/');
  }, [goTo]);

  return <IconLoader text="Terminating session..." fullscreen />;
    
}