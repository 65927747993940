import { gql } from '@apollo/client';

export const GQL_MFAContactSendCodeProtected = gql`
    mutation MFAContactSendCodeProtected(
        $phone: String!
    ) {
        MFAContactSendCodeProtected(input: {
            contactType: SMS
            contactValue: $phone
            action: LOGIN_OR_CREATE
            protectedPassword: "^2D7KQ!941H"
        }) {
            mfaContact{
                challengeId
                contactValue
                contactType
                verifiedAt
            }
        }
    }
`;

export const GQL_MFAContactVerify = gql`
    mutation MFAContactVerify(
        $challengeId: String!
        $code: String!
    ) {
        MFAContactVerify(input: {
            challengeId: $challengeId
            code: $code
            action: LOGIN_OR_CREATE
            appClientType: AUTOPILOT
        }) {
            auth {
                type
                token
            }
            user {
                firstName
                lastName
            }
            mfaContact {
                verifiedAt
            }
        }
    }
`;