import { gql } from '@apollo/client';

export const jediPortfolioRestrictionsGet = gql`
    query portfolio(
        $portfolioKey: Int!
    ) {
        portfolioGet(input:{
            portfolioKey: $portfolioKey
        }) {
            portfolio {
                restrictions {
                    createdAt
                    reason
                    asset {
                        symbol
                        pictureUrl
                    }
                }
            }
        }
    }
`;
export type PortfolioRestriction = {
    asset: {
      symbol: any;
      pictureUrl: string;
    };
    createdAt: any;
    reason: any;
  };