import React from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {useQueryAsUser} from '../../clients/graphql/graphql.hooks';
import {
    JediFailedAutopilotOrder,
    jediFailedAutopilotOrdersGet
} from '../../clients/graphql/portfolio/failed-autopilot-orders.query';
import {CircularProgress, Typography, Tooltip, SxProps} from '@mui/material';
import moment from 'moment';
import {StockSymbolComponent} from "../../pages/users/StockSymbolComponent";
import './table.css';
import Box from "@mui/material/Box";

interface Props {
    userKey?: number;
    autoPilotSettingsKey?: number;
    portfolioKey?: number;
    sx?: SxProps
}

export const FailedAutopilotOrdersTable: React.FC<Props> = ({ userKey, autoPilotSettingsKey, portfolioKey, sx}) => {
    const { data, loading, error } = useQueryAsUser(jediFailedAutopilotOrdersGet, { variables: { userKey, autoPilotSettingsKey, portfolioKey, limit: 100, offset: 0 } });
    const orders = data?.jediFailedAutopilotOrdersGet?.autoPilotOrders;

    if (loading) {
        return <CircularProgress sx={{marginLeft: 'calc(50% - 10px)', marginTop: '50px'}}/>
    }
    if (error) {
        return <div>Error fetching data. Please reload page</div>;
    }

    const columns: GridColDef[] = [
        {
            field: 'pictureUrl',
            headerName: 'Asset',
            width: 90,
            renderCell: (params) => (
                <StockSymbolComponent symbol={params.row.symbol} logo={params.row.pictureUrl}
                                      value={params.row.symbol}/>
            )
        },
        {field: 'orderSide', headerName: 'Order Side', width: 90},
        {field: 'quantity', headerName: 'Quantity', width: 90},
        {field: 'averagePrice', headerName: 'Price', width: 90},
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 150,
            valueFormatter: (value) => value ? moment(value).format('MM/DD/YYYY HH:mm') : ''
        },
        {
            field: 'failureReason',
            headerName: 'Failure Reason',
            width: 270 ,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%'}}>
                    <Tooltip title={params.row.failureReason}>
                        <Typography
                            title={params.row.failureReason}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: 'small',
                            }}>{params.row.failureReason}</Typography>
                    </Tooltip>
                </div>
        ),
        },
    ];

    const rows = orders.map((row: JediFailedAutopilotOrder, index: number) => {
        return {
            id: index, 
            pictureUrl: row.asset.pictureUrl,
            ...row,
        } 
    });

    return (

            <Box style={{height: '30rem'}}>
            <Typography align={'left'} sx={{
                color: '#6D6D6D',
                font: 'Roboto',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '12px',
                letterSpacing: '0.05em',
                marginTop: '1rem',
                paddingBottom: '.75rem',
            }}>
                FAILED AUTOPILOT ORDERS
            </Typography>
            <DataGrid
                rows={rows}
                columns={columns}
                rowHeight={40}
                getRowClassName={() => 'custom-row-padding'}
                hideFooter={true}
                sx={{
                    height: '26rem',
                }}
            />
        </Box>
    );
};
