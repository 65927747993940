export class EntityClient {

  constructor(clientBase) {
    this.client = clientBase;
  }

  async listEntity(
    entityType,
    page,
    filters = []
  ) {
    let endpoint = `/entity/${entityType}?page=${page}`;
    for (let i = 0; i < filters.length; i++) {
      endpoint += filters[i].build(i + 1);
    }
    return this.client.sendRequest(
      'GET',
      endpoint,
      {}
    );
  }
}

export class EntityFilter {
  #op;
  #field;
  #value;
  withField(field) {
    this.field = field;
    return this;
  }
  value(value) {
    this.value = value;
    return this;
  }
  eq() {
    this.op = 'eq';
    return this;
  }
  build(key) {
    return `&where[${this.field}:cmp${key}]=${this.op}&where[${this.field}:val${key}]=${this.value}`;
  }
}