import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jediAssetQueryBySymbol, jediUpdateAssetMutation } from '../../clients/graphql/asset/asset-info.query';
import {
    TextField,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert, AlertTitle
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {useMutationCoordinate, useQueryCoordinate} from "../../clients/graphql/graphql.hooks";

const UpdateAssetFormPage = () => {
    const { symbol } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [picture, setPicture] = useState(null);
    const [pictureData, setPictureData] = useState(null);
    const [pictureName, setPictureName] = useState(null);
    const [initialPictureUrl, setInitialPictureUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const [initialName, setInitialName] = useState('');
    const [initialDescription, setInitialDescription] = useState('');

    const { data, loading: queryLoading, error: queryError } = useQueryCoordinate(jediAssetQueryBySymbol, {
        variables: { symbol: symbol.toUpperCase() },
    });

    const [updateAsset] = useMutationCoordinate(jediUpdateAssetMutation,{});

    useEffect(() => {
        if (data?.assetSearch?.assets.length > 0) {
            const asset = data.assetSearch.assets[0];
            setName(asset.name);
            setDescription(asset.description);
            setInitialName(asset.name);
            setInitialDescription(asset.description);
            setInitialPictureUrl(asset.pictureUrl);
        } else if (queryError) {
            setError(queryError.message);
        } else if (data?.assetSearch?.assets.length === 0) {
            setError('Asset not found. Please check the symbol in the URL and try again.');
        }
    }, [data, queryError]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setPicture(file);

        const reader = new FileReader();
        reader.onload = () => {
            setPictureData(reader.result.split(',')[1]);
            setPictureName(file.name);
        };
        reader.onerror = () => setError('Error reading file');
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response =await updateAsset({
                variables: {
                    symbol: symbol.toUpperCase(),
                    name,
                    description,
                    pictureData,
                    pictureName,
                },
            });
            // Reset the states
            setInitialPictureUrl(response.data.jediUpdateAsset.asset.pictureUrl);
            setPicture(null);
            setPictureData(null);
            setPictureName(null);
            setLoading(false);
            setSuccess(true);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    const handleDialogClose = () => {
        setSuccess(false);
        setError('');
        setLoading(false);
    };

    const isChanged = () => {
        return (
            name !== initialName ||
            description !== initialDescription ||
            picture !== null
        );
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    disabled={queryLoading}
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    disabled={queryLoading}
                />
                <input
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span" disabled={queryLoading}>
                        Upload Photo
                    </Button>
                </label>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                    <Alert severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        Please ensure images are under 100KB before submitting.
                    </Alert>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                    {initialPictureUrl && (
                        <div style={{ textAlign: 'center', marginRight: '16px' }}>
                            <div>Current Image</div>
                            <img src={initialPictureUrl} alt="Initial" style={{ width: '100px', height: '100px', borderRadius: '10px', border: '1px solid black' }} />
                        </div>
                    )}
                    {pictureData && (
                        <div style={{ textAlign: 'center' }}>
                            <div>New Image</div>
                            <img src={`data:image/*;base64,${pictureData}`} alt="Selected" style={{ width: '100px', height: '100px', borderRadius: '10px', border: '1px solid black' }} />
                        </div>
                    )}
                </div>
                <div style={{ marginTop: '16px' }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading || queryLoading || !isChanged() || !data?.assetSearch?.assets.length}
                        startIcon={loading && <CircularProgress size="1rem" />}
                    >
                        {loading ? 'Updating...' : 'Submit'}
                    </Button>
                </div>
            </form>

            <Dialog open={success} onClose={handleDialogClose}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                        Asset updated successfully!
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={Boolean(error)} onClose={handleDialogClose}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorIcon style={{ color: 'red', marginRight: '8px' }} />
                        {error}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpdateAssetFormPage;
