import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material';
import { useQueryAsUser } from '../../clients/graphql/graphql.hooks';
import { GQL_GetWarnings } from '../../clients/graphql/user/user-info.query';

export type UserWarningListProps = {
  userKey: string,
}

export function UserWarningList({ userKey }: UserWarningListProps) {

  const { loading, error, data } = useQueryAsUser(GQL_GetWarnings, {
    userKey,
    variables: { userKey }
  });

  if (loading) {
    return <CircularProgress size={60} sx={{ margin: '30px' }} />;
  }

  if (error) {
    return <Alert severity="warning">INTERNAL ERROR: could not load user warning logs</Alert>;
  }
  const warningList = data?.userAutoPilotWarningsGet?.warningPrompts ?? [];
  return <Box>
    {warningList.map((w: any, index: number) => <Alert key={index} severity="error" sx={{margin: 2}}>
      <AlertTitle>{w?.title}</AlertTitle>
      {w?.description}
    </Alert>)}
  </Box>;
}