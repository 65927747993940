import { gql } from "@apollo/client";
import { BROKER_NAME } from "../../../shared/portfolio-types";

export type InstitutionList = {
  instutions: InstitutionData[],
}
export type InstitutionData = {
  institutionKey: number,
  institutionName: BROKER_NAME,
  connectionStatus: string,
  portfolios: InstitutionPortfolio[]
}
export type InstitutionPortfolio = {
  portfolioKey: number,
  nickname: string,
  accountId: string,
}
export const listInstitutionsQuery = gql`
  query {
    institutionsGet(input: {connectionStatus: [CONNECTED, DISCONNECTED]}) {
      institutions {
        institutionKey
        institutionName
        connectionStatus
        portfolios {
          portfolioKey
          nickname
          accountId
        }
      }
    }
  }
`;